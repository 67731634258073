
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';

import Store from '@/store';

import ProjectService from '@/services/project.service';

import Layout from '@/components/Layout.vue';
import Loader from '@/components/Loader.vue';
import SVG from '@/components/SVG.vue';
import Card from '@/components/project/Card.vue';
import CreateCard from '@/components/project/CreateCard.vue';

import Project from '@/models/project.model';
import User from '@/models/user.model';

export default defineComponent({
	components: {
		Layout,
		Loader,
		Card,
		CreateCard,
		SVG,
	},

	setup() {
		Store.set('header.contextMenu', {
			type: 'project',
			showArchive: false,
		});

		const contextMenu = Store.get('header.contextMenu');

		const projects: Ref<Project[]> = ref([]);
		const loading = ref(true);

		// load the projects
		onMounted(async () => {
			await ProjectService.get()
				.then((response) => {
					if (response) {
						projects.value = response;
					}
				})
				.finally(() => {
					loading.value = false;
				});
		});

		const allProjects = computed(() => {
			if (Store.get('header.contextMenu').value?.showArchive) {
				return projects.value.filter((project: Project) => project.archived);
			}

			return projects.value.filter((project: Project) => !project.archived);
		});

		return {
			// data
			loading,
			contextMenu,

			projects: allProjects,
			myProjects: computed(() => {
				const userId = Store.getUser().value?.id;

				return allProjects.value.filter(
					(project: Project) =>
						project.members.map((user: User) => user.id).includes(userId) || project.owner?.id == userId
				);
			}),

			otherProjects: computed(() => {
				const userId = Store.getUser().value?.id;

				return allProjects.value.filter(
					(project: Project) =>
						!project.members.map((user: User) => user.id).includes(userId) && project.owner?.id != userId
				);
			}),

			showBackButton: computed(() => {
				return Store.get('header.contextMenu').value?.showArchive;
			}),
		};
	},
});
