
import { computed, defineComponent } from 'vue';

import SVG from '@/components/SVG.vue';
import UserList from '@/components/UserList.vue';
import Store from '@/store';

export default defineComponent({
	props: ['project'],

	components: {
		SVG,
		UserList,
	},

	setup(props) {
		const date = computed(() => {
			return new Date(props.project?.updated_at).format('%-d. %mn3');
		});

		function setProject() {
			Store.set('project', props.project);
		}

		return {
			date,

			setProject,
		};
	},
});
